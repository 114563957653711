import { styled } from '@/app/styles/mainStyles';
export var Space = styled('div', {
    display: 'flex',
    gap: 0,
    variants: {
        size: {
            small: {
                gap: 4,
            },
            default: {
                gap: 8,
            },
            medium: {
                gap: 12,
            },
            middle: {
                gap: 20,
            },
            big: {
                gap: 24,
            },
            large: {
                gap: 32,
            },
        },
        align: {
            center: {
                alignItems: 'center',
            },
            flexEnd: {
                alignItems: 'flex-end',
            },
            flexStart: {
                alignItems: 'flex-start',
            },
        },
        textAlign: {
            center: {
                textAlign: 'center',
            },
        },
        content: {
            center: {
                justifyContent: 'center',
            },
            flexStart: {
                justifyContent: 'flex-start',
            },
            flexEnd: {
                justifyContent: 'flex-end',
            },
            between: {
                justifyContent: 'space-between',
            },
            around: {
                justifyContent: 'space-around',
            },
        },
        direction: {
            vertical: {
                flexDirection: 'column',
            },
            horizontal: {
                flexDirection: 'row',
            },
        },
    },
});
