var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MainContainer, setupGlobalStyles, darkTheme } from '@/app/styles/mainStyles';
import { AppRouter } from '@/app/providers/router/ui/AppRouter';
import { Toaster } from 'react-hot-toast';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var App = function () {
    setupGlobalStyles();
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    console.log('build', '1.1.0');
    return (_jsxs(_Fragment, { children: [_jsx(MainContainer, __assign({ className: "".concat(currentTheme === 'dark' ? darkTheme : '', " main-container") }, { children: _jsx(AppRouter, {}) })), _jsx(Toaster, { containerStyle: { zIndex: 999999 } })] }));
};
export { App };
