import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Redirect } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
var WithAuth = function (_a) {
    var children = _a.children, isAuth = _a.isAuth;
    if (!isAuth) {
        return _jsx(Redirect, { to: RoutePath.main });
    }
    return (_jsx(_Fragment, { children: children }));
};
export { WithAuth };
