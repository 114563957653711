import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Logo } from '@/shared/assets/icons/LOGO.svg';
import { ReactComponent as ShortLogo } from '@/shared/assets/icons/ShortLogo.svg';
export var HeaderWrapper = styled('header', {
    boxSizing: 'border-box',
    background: '$bgTransparent',
    borderBottom: '1px solid $divider',
    backdropFilter: 'blur(10px)',
    height: '80px',
    position: 'sticky',
    top: 0,
    zIndex: 999,
});
export var HeaderWrapperWithoutAuth = styled('header', {
    boxSizing: 'border-box',
    height: '80px',
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: 99998,
    transition: 'all 0.3s ease',
    variants: {
        scrolling: {
            true: {
                backdropFilter: 'blur(10px)',
                background: '$bgTransparent',
            },
        },
    },
    '@mobile': {
        height: '60px',
    },
});
export var HeaderContainer = styled('div', {
    display: 'flex',
    boxSizing: 'border-box',
    maxWidth: '1440px',
    margin: '0 auto',
    justifyContent: 'space-between',
    padding: '0px 96px',
    height: '100%',
    '@mobile': {
        padding: '0 16px',
    },
    '@tablet': {
        padding: '0 16px',
    },
});
export var LogoContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0',
    cursor: 'pointer',
});
export var DesktopLogo = styled(Logo, {
    display: 'flex',
    alignItems: 'center',
    color: '$primaryText',
    '@mobile': {
        display: 'none',
    },
});
export var MobileLogo = styled(ShortLogo, {
    display: 'none',
    color: '$primaryText',
    '@mobile': {
        display: 'block',
    },
});
export var MenuContainer = styled('div', {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'none',
    '@tablet': {
        display: 'flex',
    },
    '@mobile': {
        display: 'flex',
    },
});
export var NavContainer = styled('div', {
    width: '60%',
    height: '100%',
    '@tablet': {
        display: 'none',
    },
    '@mobile': {
        display: 'none',
    },
});
export var ProfileContainer = styled('div', {
    display: 'flex',
    padding: '24px 0',
});
