import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { ReactComponent as News } from '@/shared/assets/icons/News.svg';
import { ReactComponent as Risk } from '@/shared/assets/icons/Risk.svg';
export var navItems = [
    {
        id: 'strategies',
        text: 'Strategies',
        route: RoutePath.strategies,
    },
    {
        id: 'workspace',
        text: 'Workspace',
        route: RoutePath.workspace,
    },
    {
        id: 'news',
        text: 'News',
        route: RoutePath.news,
        children: [
            {
                id: 'newsTrends',
                text: 'Trends',
                icon: Risk,
                route: RoutePath.newsTrends,
            },
            {
                id: 'news',
                text: 'News Flow',
                icon: News,
                route: RoutePath.news,
            },
        ],
    },
    {
        id: 'plans',
        text: 'Pricing',
        route: RoutePath.plans,
    },
    {
        id: 'partnership',
        text: 'Partnership',
        route: RoutePath.partnership,
    },
];
export var navItemsWithoutAuth = [
    {
        id: 'strategies',
        text: 'Strategies',
        route: RoutePath.strategies,
    },
    {
        id: 'workspacePreview',
        text: 'Workspace',
        route: RoutePath.workspacePreview,
    },
    {
        id: 'news',
        text: 'News',
        route: RoutePath.news,
        children: [
            {
                id: 'newsTrends',
                text: 'Trends',
                icon: Risk,
                route: RoutePath.newsTrends,
            },
            {
                id: 'news',
                text: 'News Flow',
                icon: News,
                route: RoutePath.news,
            },
        ],
    },
    {
        id: 'plans',
        text: 'Pricing',
        route: RoutePath.plans,
    },
    {
        id: 'partnership',
        text: 'Partnership',
        route: RoutePath.partnership,
    },
];
