import { keyframes } from '@stitches/react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { styled } from '@/app/styles/mainStyles';
export { Trigger, Root, Provider, Portal } from '@radix-ui/react-tooltip';
var slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
var slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
var slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
var slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
var StyledContent = styled(TooltipPrimitive.Content, {
    display: 'flex',
    color: '$white',
    maxWidth: '530px',
    borderRadius: '6px',
    justifyContent: 'center',
    zIndex: '99999999',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: '$montserrat',
    boxShadow: '0px 2px 8px 0px rgba(54, 25, 88, 0.05)',
    variants: {
        theme: {
            dark: {
                backgroundColor: '$primaryText',
                fontSize: '14px',
                color: '$white',
            },
            white: {
                backgroundColor: '$white',
                color: '$black',
                boxShadow: '1px 1px 20px 1px rgba(0, 0, 0, 0.10)',
            },
        },
    },
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        animationFillMode: 'forwards',
        willChange: 'transform, opacity',
        '&[data-state="delayed-open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },
    '@tablet': {
        whiteSpace: 'pre-wrap',
    },
});
var StyledArrow = styled(TooltipPrimitive.Arrow, {
    variants: {
        theme: {
            dark: {
                fill: '$black',
            },
            white: {
                fill: '$white',
            },
        },
    },
});
// Exports
export var Content = StyledContent;
export var Arrow = StyledArrow;
