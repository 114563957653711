import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Logo } from '@/shared/assets/icons/LOGO.svg';
import { ReactComponent as ShortLogo } from '@/shared/assets/icons/ShortLogo.svg';
export var FooterWrapper = styled('div', {
    boxSizing: 'border-box',
    maxWidth: '1440px',
    width: '100%',
    padding: '0 95px',
    margin: '120px auto',
    '@mobile': {
        padding: '0 16px',
    },
    '@tablet': {
        padding: '0 16px',
    },
});
export var FooterText = styled('p', {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '$inputBorderHover',
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
    },
});
export var Footer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    width: '100%',
    background: '#1D2023',
    padding: '52px 80px',
    margin: '0 auto',
    borderRadius: '40px',
    '@mobile': {
        flexDirection: 'column',
        padding: '20px 20px 32px 20px',
    },
    '@tablet': {
        flexDirection: 'column',
    },
});
export var FooterLeftColumn = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    '@mobile': {
        gap: '16px',
    },
});
export var FooterLogo = styled('div', {
    display: 'inline-flex',
    color: '$white',
});
export var LogoIcon = styled(Logo, {
    color: '$alwaysWhite',
    display: 'block',
    '@mobile': {
        display: 'none',
    },
});
export var ShortLogoIcon = styled(ShortLogo, {
    color: '$alwaysWhite',
    display: 'none',
    width: '25px',
    height: '25px',
    '@mobile': {
        display: 'block',
    },
});
export var FooterLeftText = styled(FooterText, {
    color: '#B8C0CC',
    marginBottom: '8px',
    '&:last-child': {
        marginBottom: 0,
    },
});
export var FooterSocialMedia = styled('div', {
    color: '$white',
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    '@mobile': {
        margin: '8px 0 24px 0',
    },
});
export var FooterRightColumn = styled('div', {
    display: 'flex',
    paddingTop: '10px',
    '@mobile': {
        paddingTop: '0',
    },
});
export var FooterLinksWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});
export var FooterCategoriesWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    '@mobile': {
        gap: '64px',
    },
});
export var FooterLinks = styled('ul', {
    listStyleType: 'none',
    display: 'flex',
    margin: '0 0 0 -17px',
    alignItems: 'center',
    gap: '20px',
    '@mobile': {
        flexWrap: 'wrap',
        maxWidth: '210px',
    },
});
export var FooterLink = styled('li', {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '#FFFFFF',
    cursor: 'pointer',
    transition: 'opacity .2s',
    '&:hover': {
        opacity: '.7',
    },
    '@mobile': {
        fontSize: '16px',
        lineHeight: '24px',
    },
});
