var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { forwardRef, useState } from 'react';
import { PasswordTypeEnum } from './types';
import { Space } from '@/shared/ui/Space/Space';
import { Text } from '@/shared/ui';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeCross } from '../../assets/icons/eye-cross.svg';
// import { Text } from '@/shared/ui/Text/Text';
var Input = forwardRef(function (_a, ref) {
    var value = _a.value, css = _a.css, size = _a.size, autocomplete = _a.autocomplete, onChange = _a.onChange, iconInside = _a.iconInside, _b = _a.typeInput, typeInput = _b === void 0 ? 'default' : _b, type = _a.type, withLabel = _a.withLabel, placeholder = _a.placeholder, error = _a.error, required = _a.required, label = _a.label, onBlur = _a.onBlur, onFocus = _a.onFocus, onKeyPress = _a.onKeyPress, autoFocus = _a.autoFocus, password = _a.password, tooltip = _a.tooltip, _c = _a.disabled, disabled = _c === void 0 ? false : _c, props = __rest(_a, ["value", "css", "size", "autocomplete", "onChange", "iconInside", "typeInput", "type", "withLabel", "placeholder", "error", "required", "label", "onBlur", "onFocus", "onKeyPress", "autoFocus", "password", "tooltip", "disabled"]);
    var _d = useState(PasswordTypeEnum.password), passwordType = _d[0], setPasswordType = _d[1];
    var _e = useState(false), isFocus = _e[0], setIsFocus = _e[1];
    var handleChange = function (event) {
        if (onChange) {
            onChange(event);
        }
    };
    var changePasswordType = function () {
        setPasswordType(function (prevState) {
            return prevState === PasswordTypeEnum.password ? PasswordTypeEnum.text : PasswordTypeEnum.password;
        });
    };
    var getError = function () {
        return typeof error === 'object';
    };
    var handleBlur = function (event) {
        if (onBlur) {
            onBlur(event);
        }
    };
    var handleFocus = function (event) {
        if (onFocus) {
            onFocus(event);
        }
    };
    var handleKeyPress = function (event) {
        if (onKeyPress) {
            onKeyPress(event);
        }
    };
    var handleFocusInput = function () {
        setIsFocus(true);
    };
    var handleOnBlurInput = function (e) {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsFocus(false);
        }
    };
    return (_jsxs(Space, __assign({ css: { width: '100%', position: 'relative' }, direction: "vertical" }, { children: [_jsxs(S.InputWrapper, __assign({ "data-state-disabled": disabled, onBlur: handleOnBlurInput, onFocus: handleFocusInput, type: typeInput, error: getError() }, { children: [_jsx(S.Input, __assign({ disabled: disabled, 
                        // onClick={(event) => event.stopPropagation()}
                        size: size, withLabel: withLabel, type: type === PasswordTypeEnum.password ? passwordType : type, typeInput: typeInput, value: value, iconInside: iconInside, onBlur: handleBlur, onFocus: handleFocus, "data-value-attribute": !!value, onKeyPress: handleKeyPress, placeholder: !withLabel ? placeholder : '', ref: ref, autoFocus: autoFocus, autoComplete: autocomplete || 'on', onChange: function (event) { return handleChange(event); }, css: css }, props)), type === PasswordTypeEnum.password && (value || isFocus) && (_jsx(S.IconWrapper, __assign({ type: 'button', onClick: changePasswordType }, { children: passwordType === PasswordTypeEnum.password ? _jsx(Eye, {}) : _jsx(EyeCross, {}) })))] })), (withLabel && placeholder) &&
                _jsx(S.Label, __assign({ "data-state-disabled": disabled, "data-value-attribute": Number.isInteger(value) ? true : !!value }, { children: placeholder })), getError() &&
                _jsx(S.ErrorContainer, { children: _jsx(Text, __assign({ type: "errorInput" }, { children: error === null || error === void 0 ? void 0 : error.message })) })] })));
});
export { Input };
