var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import * as S from './style';
var Dropdown = function (props) {
    var children = props.children, _a = props.text, text = _a === void 0 ? '' : _a, _b = props.cssContent, cssContent = _b === void 0 ? {} : _b, _c = props.active, active = _c === void 0 ? false : _c;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var onMouseOver = function () {
        setIsOpen(true);
    };
    var onMouseLeave = function () {
        setIsOpen(false);
    };
    return (_jsxs(S.Dropdown, __assign({ active: active, onMouseOver: onMouseOver, onMouseLeave: onMouseLeave }, { children: [_jsxs(S.DropdownTrigger, __assign({ "data-state-open": isOpen }, { children: [text, _jsx(S.ArrowIcon, { "data-state-open": isOpen || active })] })), _jsx(S.DropdownContent, __assign({ "data-state-open": isOpen, css: cssContent }, { children: children }))] })));
};
export { Dropdown };
