var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ReactComponent as Tick } from '@/shared/assets/icons/tick.svg';
import * as S from './style';
var Checkbox = function (_a) {
    var checked = _a.checked, onChange = _a.onChange, css = _a.css, onClick = _a.onClick;
    var handleChecked = function () {
        if (onClick) {
            onClick();
        }
        if (onChange) {
            onChange(!checked);
        }
    };
    return (_jsx(S.CheckboxContainer, __assign({ onClick: handleChecked, css: css, checked: checked }, { children: checked && (_jsx(Tick, { fill: '#FFF', width: 15, height: 12.5 })) })));
};
export { Checkbox };
