var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import * as S from './style';
var NavigationItem = function (props) {
    var active = props.active, id = props.id, text = props.text, Icon = props.icon;
    var history = useHistory();
    var navigate = function (path) {
        history.push(path);
    };
    return (_jsxs(S.NavigationItem, __assign({ active: active, onClick: function () { return navigate(id); } }, { children: [_jsx(S.NavigationIcon, { children: Icon && _jsx(Icon, { css: { fill: '$primaryText' } }) }), _jsx(S.TooltipText, { children: text })] })));
};
export { NavigationItem };
