var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
var initialState = {
    strategyBlockResult: null,
    strategyBlockStatus: BASE_FETCH_STATUS,
    taskIdResult: null,
    taskIdStatus: BASE_FETCH_STATUS,
    runResult: null,
    runStatus: BASE_FETCH_STATUS,
    runStrategyResult: null,
    runStrategyStatus: BASE_FETCH_STATUS,
    isShowData: true,
    tickersInfoResult: null,
    tickersInfoStatus: BASE_FETCH_STATUS,
};
var getTickersInfo = createAsyncThunk('runSlice/getTickersInfo', function (_a, thunkAPI) {
    var ticker_ids = _a.ticker_ids;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post('/tickers/tickers_details/', { ticker_ids: ticker_ids })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getRunData = createAsyncThunk('runSlice/getRunData', function (_a, thunkAPI) {
    var task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-workspace/run/get_result/', { task_id: task_id })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_2)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getTaskId = createAsyncThunk('runSlice/getTaskId', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-workspace/".concat(payload.id, "/run/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getStrategyBlockData = createAsyncThunk('runSlice/getStrategyBlockData', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var pollTaskStatus = createAsyncThunk('runSlice/pollTaskStatus', function (_a, thunkAPI) {
    var task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var status, blockIds, isShowData, response, data, timestampKey, blocks, numberArray;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    status = 'RUNNING';
                    blockIds = [];
                    _b.label = 1;
                case 1:
                    if (!(status !== 'SUCCESS')) return [3 /*break*/, 4];
                    isShowData = thunkAPI.getState().runReducer.isShowData;
                    if (!isShowData) {
                        return [2 /*return*/, thunkAPI.rejectWithValue('Показ данных остановлен')];
                    }
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-workspace/run/get_result/', { task_id: task_id })];
                case 3:
                    response = _b.sent();
                    data = response.data;
                    status = data.task_status;
                    if (data.data && Object.keys(data.data).length > 0) {
                        timestampKey = Object.keys(data.data)[0];
                        blocks = data.data[timestampKey];
                        blockIds = Object.keys(blocks).map(function (key) { return parseInt(key); });
                    }
                    if (status === 'FAILURE') {
                        return [2 /*return*/, thunkAPI.rejectWithValue('Ошибка подсчета данных')];
                    }
                    if (status === 'SUCCESS') {
                        numberArray = blockIds.map(Number);
                        return [2 /*return*/, { data: data, numberArray: numberArray }];
                    }
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    });
});
var runStrategy = createAsyncThunk('runSlice/runStrategy', function (_a, thunkAPI) {
    var blockId = _a.blockId, strategyUrl = _a.strategyUrl;
    return __awaiter(void 0, void 0, void 0, function () {
        var urlParts, strategy, taskResponse, _b, data, numberArray, timestampKey, blocks_1, blockTypes_1, typeMapping_1, blockIds, error_3;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    urlParts = strategyUrl.split('/');
                    strategy = urlParts[urlParts.length - 1];
                    return [4 /*yield*/, thunkAPI.dispatch(getTaskId({ id: strategy, data: { block_pk: blockId, force_execute: true } })).unwrap()];
                case 1:
                    taskResponse = _c.sent();
                    return [4 /*yield*/, thunkAPI.dispatch(pollTaskStatus({ task_id: taskResponse.task_id })).unwrap()];
                case 2:
                    _b = _c.sent(), data = _b.data, numberArray = _b.numberArray;
                    console.log('data', data);
                    timestampKey = Object.keys(data.data)[0];
                    blocks_1 = data.data[timestampKey];
                    return [4 /*yield*/, Promise.all(numberArray.map(function (blockId) { return __awaiter(void 0, void 0, void 0, function () {
                            var blockData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, thunkAPI.dispatch(getStrategyBlockData({ id: blockId })).unwrap()];
                                    case 1:
                                        blockData = _a.sent();
                                        return [2 /*return*/, blockData.strategy_step_type];
                                }
                            });
                        }); }))];
                case 3:
                    blockTypes_1 = _c.sent();
                    typeMapping_1 = {
                        0: 'filter',
                        1: 'strategist',
                        2: 'strategist',
                        3: 'weighter',
                        4: 'weighter',
                        5: 'dealEntry',
                        6: 'dealExit',
                    };
                    blockIds = Object.keys(blocks_1).map(Number);
                    blockIds.forEach(function (typeId, index) {
                        if (blocks_1[typeId]) {
                            var typeIndex = blockTypes_1[index];
                            var typeValue = typeMapping_1[typeIndex] || typeIndex;
                            blocks_1[typeId].type_block = typeValue;
                        }
                    });
                    console.log('blockTypes', blockTypes_1);
                    console.log('blocks', blocks_1);
                    return [2 /*return*/, blocks_1];
                case 4:
                    error_3 = _c.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_3)];
                case 5: return [2 /*return*/];
            }
        });
    });
});
var runSlice = createSlice({
    name: 'runSlice',
    initialState: initialState,
    reducers: {
        clearRunStrategyData: function (state) {
            state.runStrategyResult = null;
            state.runStrategyStatus = BASE_FETCH_STATUS;
        },
        toggleShowData: function (state, _a) {
            var payload = _a.payload;
            state.isShowData = payload;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getTaskId.pending, function (state) {
            state.taskIdStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTaskId.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.taskIdResult = payload;
            state.taskIdStatus.isLoading = false;
        });
        builder.addCase(getTaskId.rejected, function (state, _a) {
            var payload = _a.payload;
            state.taskIdStatus.error = payload;
            state.taskIdStatus.isLoading = false;
        });
        builder.addCase(getRunData.pending, function (state) {
            state.runStatus = START_FETCH_STATUS;
        });
        builder.addCase(getRunData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.runResult = payload;
            state.runStatus.isLoading = false;
        });
        builder.addCase(getRunData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.runStatus.error = payload;
            state.runStatus.isLoading = false;
        });
        builder.addCase(getStrategyBlockData.pending, function (state) {
            state.strategyBlockStatus = START_FETCH_STATUS;
        });
        builder.addCase(getStrategyBlockData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.strategyBlockResult = payload;
            state.strategyBlockStatus.isLoading = false;
        });
        builder.addCase(getStrategyBlockData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.strategyBlockStatus.error = payload;
            state.strategyBlockStatus.isLoading = false;
        });
        builder.addCase(runStrategy.pending, function (state) {
            state.runStrategyStatus = START_FETCH_STATUS;
            state.runStrategyResult = null;
        });
        builder.addCase(runStrategy.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.runStrategyResult = payload;
            state.runStrategyStatus.isLoading = false;
        });
        builder.addCase(runStrategy.rejected, function (state, _a) {
            var payload = _a.payload;
            state.runStrategyStatus.error = payload;
            state.runStrategyStatus.isLoading = false;
            state.runStrategyResult = null;
        });
        builder.addCase(getTickersInfo.pending, function (state) {
            state.tickersInfoStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTickersInfo.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.tickersInfoResult = payload;
            state.tickersInfoStatus.isLoading = false;
        });
        builder.addCase(getTickersInfo.rejected, function (state, _a) {
            var payload = _a.payload;
            state.tickersInfoStatus.error = payload;
            state.tickersInfoStatus.isLoading = false;
        });
    },
});
export var clearRunStrategyData = (_a = runSlice.actions, _a.clearRunStrategyData), toggleShowData = _a.toggleShowData;
export default runSlice.reducer;
export { getTaskId, getStrategyBlockData, runStrategy, getRunData, getTickersInfo };
