var _a, _b;
import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export var NavContainer = styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
});
export var HeaderText = styled(Text, {});
export var NavItem = styled('div', {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '2px solid transparent',
    '&:hover': (_a = {
            cursor: 'pointer'
        },
        _a["".concat(HeaderText)] = {
            color: '$lightTextDefault',
        },
        _a),
    variants: {
        active: {
            true: (_b = {
                    borderBottom: '2px solid $lightTextDefault'
                },
                _b["".concat(HeaderText)] = {
                    color: '$lightTextDefault',
                },
                _b),
        },
    },
});
