var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
var MainLayout = function (_a) {
    var children = _a.children, _b = _a.isApplyLayout, isApplyLayout = _b === void 0 ? true : _b, _c = _a.background, background = _c === void 0 ? 'gray' : _c;
    return (_jsx(S.MainLayoutWrapper, __assign({ background: background }, { children: isApplyLayout ? (_jsx(S.MainLayout, __assign({ id: 'mainLayout' }, { children: children }))) : children })));
};
export { MainLayout };
