import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    theme: localStorage.getItem('theme') || 'dark',
};
var themeSlice = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        toggleTheme: function (state) {
            var newTheme = state.theme === 'dark' ? 'light' : 'dark';
            state.theme = newTheme;
            localStorage.setItem('theme', newTheme);
            console.log('1', state.theme);
            console.log('2', localStorage.getItem('theme'));
        },
    },
});
export var toggleTheme = themeSlice.actions.toggleTheme;
export default themeSlice.reducer;
