export var typeMapping = {
    0: 'filter',
    1: 'strategist',
    2: 'strategist',
    3: 'weighter',
    4: 'weighter',
    5: 'dealEntry',
    6: 'dealExit',
};
export var fakeData = {
    'data': {
        '24-06-11T22-43-49': {
            '111': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'right': 0,
                        'right_data': 0,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'right': 0,
                        'right_data': 0,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'right': 0,
                        'right_data': 0,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'function',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '140': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '141': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '142': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '143': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
        },
        '24-05-11T22-43-49': {
            '111': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'right': 0,
                        'right_data': 0,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 222,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'right': 0,
                        'right_data': 0,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 222,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'right': 0,
                        'right_data': 0,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 222,
                    },
                },
                'symbol': '>',
                'left_data_type': 'function',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '140': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '141': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '142': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
            '143': {
                'tickers_data': {
                    '2': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                445.8,
                                445.8,
                                446.9,
                                447.3,
                                447.4,
                                446.2,
                                446.5,
                                446.5,
                                447.7,
                                446.9,
                                447.4,
                                447.3,
                                446.5,
                                446.8,
                                446.4,
                            ],
                            'period': 5,
                            'ma': [
                                446.64,
                                446.71999999999997,
                                446.86,
                                446.78000000000003,
                                446.85999999999996,
                                446.76000000000005,
                                447,
                                447.16,
                                447.16,
                                446.98,
                                446.88,
                            ],
                        },
                        'result': true,
                        'left': 446.88,
                    },
                    '3': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                3500.7,
                                3500.7,
                                3504.46,
                                3501.99,
                                3501.86,
                                3498.82,
                                3493.52,
                                3490.8,
                                3490.99,
                                3488.81,
                                3487.7,
                                3485,
                                3486.83,
                                3492.34,
                                3495,
                            ],
                            'period': 5,
                            'ma': [
                                3501.942,
                                3501.5660000000003,
                                3500.1299999999997,
                                3497.398,
                                3495.1979999999994,
                                3492.5879999999997,
                                3490.364,
                                3488.66,
                                3487.8660000000004,
                                3488.136,
                                3489.374,
                            ],
                        },
                        'result': true,
                        'left': 3489.374,
                    },
                    '6': {
                        'base_datetime': '2024-06-11T22:43:49',
                        'deals_type': 'long',
                        'weight': 0.14285714285714288,
                        'right': 1,
                        'right_data': 1,
                        'left_data': {
                            'function': 'ma',
                            'historical_price_data': [
                                62786.47,
                                62786.47,
                                62942.47,
                                62849,
                                62727.86,
                                62676.14,
                                62664.95,
                                62729.87,
                                62680.71,
                                62760.02,
                                62684.63,
                                62622.99,
                                62702.2,
                                62716.32,
                                62782.54,
                            ],
                            'period': 5,
                            'ma': [
                                62818.454000000005,
                                62796.388,
                                62772.08400000001,
                                62729.564,
                                62695.906,
                                62702.338,
                                62704.036,
                                62695.64400000001,
                                62690.11,
                                62697.231999999996,
                                62701.736,
                            ],
                        },
                        'result': true,
                        'left': 62701.736,
                    },
                },
                'symbol': '>',
                'left_data_type': 'value',
                'right_data_type': 'value',
                'in': [
                    2,
                    3,
                    6,
                ],
            },
        },
    },
    'profit': {},
};
