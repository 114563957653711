import { jsx as _jsx } from "react/jsx-runtime";
import toast from 'react-hot-toast';
import { ToastContainerWrapper } from '@/widgets/ui/Toast/ToastSuccess/style';
import * as S from './style';
var ToastErrorsContent = function (props) {
    return (_jsx(S.ToastErrorMessage, { children: props.message }));
};
var toastError = function (message, duration, position) {
    toast(function (t) { return (_jsx(ToastErrorsContent, { toast: t, message: message })); }, {
        style: ToastContainerWrapper,
        duration: duration,
        position: position,
    });
};
export { toastError };
