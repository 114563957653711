var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Text } from '@/shared/ui/Text/Text';
var AvatarWithName = function (_a) {
    var userData = _a.userData;
    return (_jsxs(S.AvatarWithNameContainer, { children: [_jsx(S.ImgContainer, { src: "https://yt3.googleusercontent.com/11LVyJ1PURturdar2RCZ-jqjdsiEW4cRSn21XYw18JiwsFmcAegORQaATpwj4pr2DoZHXzR7=s900-c-k-c0x00ffffff-no-rj" }), _jsx(Text, __assign({ type: "header" }, { children: userData === null || userData === void 0 ? void 0 : userData.first_name }))] }));
};
export { AvatarWithName };
