var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
var Text = function (_a) {
    var children = _a.children, css = _a.css, type = _a.type, userSelect = _a.userSelect, ellipse = _a.ellipse, onClick = _a.onClick, contentEditable = _a.contentEditable;
    var handleClick = function (event) {
        if (onClick) {
            onClick(event);
        }
    };
    return (_jsx(S.Text, __assign({ onClick: handleClick, userSelect: userSelect, ellipse: ellipse, css: css, type: type, contentEditable: contentEditable }, { children: children })));
};
export { Text };
