import { keyframes } from '@stitches/react';
import * as AlertDialogPrimitive from '@radix-ui/react-dialog';
import { styled } from '@/app/styles/mainStyles';
export { Root, Trigger, Portal, } from '@radix-ui/react-dialog';
var overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});
var contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});
export var Overlay = styled('div', {
    zIndex: 99998,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    pointerEvents: 'auto',
    '@media (prefers-reduced-motion: no-preference)': {
        animation: "".concat(overlayShow, " 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards"),
    },
});
export var Content = styled(AlertDialogPrimitive.Content, {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99999,
    boxSizing: 'border-box',
    borderRadius: 20,
    fontFamily: '$montserrat',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    fontSize: '.875rem',
    lineHeight: '1.375rem',
    background: '$bgSecondlayer',
    variants: {
        type: {
            runDialog: {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },
    },
    '@desktop': {
        maxHeight: '90vh',
        top: '50%',
        left: '50%',
        minWidth: 300,
    },
    '@media (prefers-reduced-motion: no-preference)': {
        animation: "".concat(contentShow, " 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards"),
    },
    '&:focus': { outline: 'none' },
    '@mobile': {
        width: '100vw !important',
        height: '100vh !important',
        top: '50%',
        left: '50%',
        borderRadius: 0,
    },
});
export var Header = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    variants: {
        type: {
            runDialog: {
                paddingLeft: '20px',
                paddingRight: '20px',
            },
        },
    },
});
export var HeaderText = styled('div', {
    display: 'block',
});
export var Title = styled(AlertDialogPrimitive.Title, {
    margin: 0,
    color: '$primaryText',
    fontSize: 28,
    fontWeight: 600,
    fontFamily: '$montserrat',
    lineHeight: '42px',
    '&[data-description=true]': {
        marginBottom: '0px',
    },
    '@tablet': {
        fontSize: 20,
    },
});
export var Description = styled(AlertDialogPrimitive.Description, {
    margin: 0,
    color: '$textSecondary',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: '$montserrat',
    lineHeight: '22px',
    '@tablet': {
        fontSize: 14,
    },
});
export var Close = styled('button', {
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '8px',
    backgroundColor: '$bgSecondlayer',
    width: '40px',
    height: '40px',
    border: 'none',
    color: '$primaryText',
    fill: '$primaryText',
    '&:active': {
        border: 'none',
    },
    'svg > g': {
        stroke: '$textDark',
    },
});
