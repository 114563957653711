var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MarkerType } from 'reactflow';
var nodesTypes = {
    0: 'filterNode',
    1: 'strategiestNodeLong',
    2: 'strategiestNodeShort',
    3: 'weightsNodeLong',
    4: 'weightsNodeShort',
    5: 'dealEntryOpenLong',
    6: 'dealExitOpenLong',
    7: 'dealEntryOpenShort',
    8: 'dealExitOpenShort',
    9: 'none',
};
export var updateSchemeNodes = function (workspaceBlocks) {
    var nodesArray = [];
    if ((workspaceBlocks === null || workspaceBlocks === void 0 ? void 0 : workspaceBlocks.length) !== 0) {
        nodesArray.push.apply(nodesArray, workspaceBlocks.map((function (el) {
            return {
                position: {
                    x: el === null || el === void 0 ? void 0 : el.x,
                    y: el === null || el === void 0 ? void 0 : el.y,
                },
                id: el.id.toString(),
                type: nodesTypes[el.strategy_step_type],
                data: {
                    label: 'Input Node',
                    id: el.id,
                    nodeData: el,
                },
                width: 110,
                height: 43,
            };
        })));
    }
    return nodesArray;
};
export var transformNode = function (node) { return ({
    position: {
        x: node === null || node === void 0 ? void 0 : node.x,
        y: node === null || node === void 0 ? void 0 : node.y,
    },
    id: node.id.toString(),
    type: nodesTypes[node.strategy_step_type],
    data: {
        label: 'Input Node',
        id: node.id,
        nodeData: node,
    },
    width: 110,
    height: 43,
}); };
export var prepareEdge = function (edge) {
    return __assign(__assign({}, edge), { sourceHandle: 'a', source: edge.source.toString(), target: edge.target.toString(), targetHandle: null, id: edge.id, type: 'custom-edge', label: edge.priority, markerEnd: {
            type: MarkerType.Arrow,
            width: 15,
            height: 15,
            color: '#00A5BC',
        }, style: {
            strokeWidth: 2,
            stroke: '#00A5BC',
        } });
};
export var getCurrentEdges = function (edgesList) {
    if (Array.isArray(edgesList)) {
        if (edgesList.length !== 0) {
            return edgesList.map(function (el) { return ({
                sourceHandle: 'a',
                source: el.source.toString(),
                target: el.target.toString(),
                type: 'custom-edge',
                label: el.priority,
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 15,
                    height: 15,
                    color: '#00A5BC',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#00A5BC',
                },
                targetHandle: null,
                id: el.id,
            }); });
        }
    }
    return [];
};
export var updateFunctionNode = function (nodes, payload) {
    return nodes.map(function (item) {
        if (+item.id === +payload.data.compare_expression) {
            if (payload.isLeft) {
                return __assign(__assign({}, item), { data: __assign(__assign({}, item.data), { nodeData: __assign(__assign({}, item.data.nodeData), { left_operand: payload.data }) }) });
            }
            else {
                return __assign(__assign({}, item), { data: __assign(__assign({}, item.data), { nodeData: __assign(__assign({}, item.data.nodeData), { right_operand: payload.data }) }) });
            }
        }
        return item;
    });
};
export var checkEdges = function (edges, payload) {
    if (edges) {
        var dictionary_1 = {};
        var source_1 = payload.source.toString();
        for (var _i = 0, edges_1 = edges; _i < edges_1.length; _i++) {
            var edge = edges_1[_i];
            if (edge.source === source_1) {
                dictionary_1[edge.label] = dictionary_1[edge.label] ? dictionary_1[edge.label] + 1 : 1;
            }
        }
        return edges.map(function (item) {
            if (item.source === source_1) {
                if (dictionary_1[item.label] > 1 || item.label === '') {
                    return __assign(__assign({}, item), { style: __assign(__assign({}, item.style), { stroke: '#D32E5F' }), markerEnd: __assign(__assign({}, item.markerEnd), { color: '#D32E5F' }) });
                }
                return __assign(__assign({}, item), { style: __assign(__assign({}, item.style), { stroke: '#00A5BC' }), markerEnd: __assign(__assign({}, item.markerEnd), { color: '#00A5BC' }) });
            }
            return item;
        });
    }
};
export var handlerErrorEdge = function (edges, id) {
    return edges.map(function (item) { return item.id === id ? __assign(__assign({}, item), { label: '', style: __assign(__assign({}, item.style), { stroke: '#D32E5F' }), markerEnd: __assign(__assign({}, item.markerEnd), { color: '#D32E5F' }) }) : item; });
};
