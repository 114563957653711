var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { ReactComponent as Menu } from '@/shared/assets/icons/BurgerMenu.svg';
import { Navigation } from '@/features/ui/Navigation/Navigation';
import { HeaderProfile } from '@/features/ui/HeaderProfile/HeaderProfile';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { getProfile } from '@/entities/user/model/userModelSlice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
var Header = function () {
    var dispatch = useTypedDispatch();
    var history = useHistory();
    var location = useLocation();
    var path = location.pathname;
    var _a = useTypedSelector(function (state) { return state.userReducer; }), userData = _a.userData, isAuth = _a.isAuth;
    var _b = useState(false), scrolling = _b[0], setScrolling = _b[1];
    useEffect(function () {
        if (isAuth) {
            dispatch(getProfile(''));
        }
    }, [isAuth]);
    var handleScroll = function () {
        if (window.scrollY > 100) {
            setScrolling(true);
        }
        else {
            setScrolling(false);
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, []);
    if (path === '/') {
        return (_jsx(S.HeaderWrapperWithoutAuth, __assign({ id: 'header', scrolling: scrolling }, { children: _jsxs(S.HeaderContainer, { children: [_jsxs(S.LogoContainer, { children: [_jsx(S.MenuContainer, { children: _jsx(Menu, { width: 48, height: 48 }) }), _jsxs("div", __assign({ onClick: function () { return history.push(RoutePath.main); } }, { children: [_jsx(S.DesktopLogo, {}), _jsx(S.MobileLogo, { width: 31, height: 31, fill: '$black700' })] }))] }), _jsx(S.NavContainer, { children: _jsx(Navigation, { token: isAuth }) }), _jsx(S.ProfileContainer, { children: _jsx(HeaderProfile, { userData: userData, token: isAuth }) })] }) })));
    }
    return (_jsx(S.HeaderWrapper, __assign({ id: 'header' }, { children: _jsxs(S.HeaderContainer, { children: [_jsxs(S.LogoContainer, { children: [_jsx(S.MenuContainer, { children: _jsx(Menu, { width: 48, height: 48 }) }), _jsxs("div", __assign({ onClick: function () { return history.push(RoutePath.main); } }, { children: [_jsx(S.DesktopLogo, {}), _jsx(S.MobileLogo, { width: 31, height: 31, fill: '$black700' })] }))] }), _jsx(S.NavContainer, { children: _jsx(Navigation, { token: isAuth }) }), _jsx(S.ProfileContainer, { children: _jsx(HeaderProfile, { userData: userData, token: isAuth }) })] }) })));
};
export { Header };
