var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text } from '@/shared/ui';
import * as S from './style';
import { ReactComponent as Warning } from '@/shared/assets/icons/Warning.svg';
import { ReactComponent as Attention } from '@/shared/assets/icons/Attention.svg';
var Informer = function (props) {
    var _a = props.type, type = _a === void 0 ? 'attention' : _a, children = props.children;
    return (_jsxs(S.InformerWrapper, __assign({ type: type }, { children: [type === 'info' &&
                _jsx(Warning, {}), type === 'attention' &&
                _jsx(Warning, {}), type === 'warning' &&
                _jsx(Attention, {}), _jsx(Text, __assign({ type: 'header', css: { width: '100%' } }, { children: children }))] })));
};
export { Informer };
