import { styled } from '@stitches/react';
export var ToggleContainer = styled('button', {
    width: '40px',
    height: '24px',
    backgroundColor: '#e7eaee',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    padding: 0,
    border: 'none',
    '&.checked': {
        backgroundColor: '#00A5BC',
        '&:hover': {
            backgroundColor: '#00A5BC',
        },
    },
    '&.enabled': {
        backgroundColor: '$darknessAquamarine',
        '&:hover': {
            backgroundColor: '$darknessAquamarine',
            cursor: 'not-allowed',
        },
    },
    '&:hover': {
        backgroundColor: '#b8c0cc',
    },
});
export var ToggleSwitch = styled('div', {
    width: '20px',
    height: '20px',
    backgroundColor: '$white',
    borderRadius: '100%',
    margin: '2px',
    transition: 'transform 0.3s ease',
    '&.checked': {
        transform: 'translateX(16px)',
    },
    '&.enabled': {
        transform: 'translateX(16px)',
    },
});
