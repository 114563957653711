var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import * as S from './style';
export var InterfaceChip = function (props) {
    var _a = props.type, type = _a === void 0 ? 'selected' : _a, loading = props.loading, className = props.className, children = props.children, onClick = props.onClick, disabled = props.disabled, css = props.css;
    var handleClick = function (e) {
        if (!disabled && onClick) {
            onClick(e);
        }
    };
    return (_jsx(S.InterfaceChip, __assign({ type: type, className: className ? "".concat(className, " button") : 'button', css: css, onClick: handleClick, disabled: disabled }, { children: loading ? 'Загрузка...' : children })));
};
