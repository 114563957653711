var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { useLocation, useHistory } from 'react-router-dom';
import { navItems, navItemsWithoutAuth } from './NavItems';
import { Fragment } from 'react';
import { Dropdown } from '@/shared/ui/Dropdown/Dropdown';
import { NavigationItem } from '@/features/ui/Navigation/ui/NavigationItem/NavigationItem';
var Navigation = function (_a) {
    var token = _a.token;
    var history = useHistory();
    var location = useLocation();
    var checkActive = function (path, route, children) {
        if (path === route) {
            return true;
        }
        for (var i = 0; i < children.length; i++) {
            if (children[i].route === path) {
                return true;
            }
        }
        return false;
    };
    var renderNavItems = function (items) {
        return items.map(function (el) {
            var _a;
            return (_jsx(Fragment, { children: ((_a = el.children) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(Dropdown, __assign({ active: checkActive(location.pathname, el.route, el.children), text: el.text, cssContent: { background: '$bgSecondlayer' } }, { children: el.children.map(function (item) { return (_jsx(NavigationItem, { active: location.pathname === item.route, id: "/".concat(item.id), text: item.text, icon: item.icon }, item.id)); }) }))) : (_jsx(S.NavItem, __assign({ active: location.pathname === el.route, onClick: function () { return history.push(el.route); } }, { children: _jsx(S.HeaderText, __assign({ type: 'header' }, { children: el.text })) }))) }, el.id));
        });
    };
    return (_jsx(S.NavContainer, { children: token ? renderNavItems(navItems) : renderNavItems(navItemsWithoutAuth) }));
};
export { Navigation };
