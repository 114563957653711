import { styled } from '@/app/styles/mainStyles';
export var IconContainer = styled('div', {
    variants: {
        type: {
            logo: {
                width: '156px',
                height: '32px',
            },
        },
        size: {
            small: {
                width: '16px',
                height: '16px',
            },
            medium: {
                width: '20px',
                height: '20px',
            },
            large: {
                width: '24px',
                height: '24px',
            },
            headToolbar: {
                width: '24px',
                height: '24px',
            },
        },
    },
});
