var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ReactComponent as DarkTheme } from '@/shared/assets/icons/darkTheme.svg';
import { ReactComponent as LightTheme } from '@/shared/assets/icons/lightTheme.svg';
import * as S from './style';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { toggleTheme } from '@/entities/theme/model/themeSlice';
export var ThemeToggle = function () {
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var dispatch = useTypedDispatch();
    var switchTheme = function () {
        dispatch(toggleTheme());
    };
    return (_jsx(S.ThemeToggleContainer, __assign({ onClick: switchTheme }, { children: currentTheme === 'dark' ? _jsx(DarkTheme, {}) : _jsx(LightTheme, {}) })));
};
