var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { ReactComponent as Search } from '@/shared/assets/icons/SearchIcon.svg';
import { ReactComponent as Notification } from '@/shared/assets/icons/NotificationIcon.svg';
import { AvatarWithName, Button, Icon } from '@/shared/ui';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { DropdownHeader } from '@/widgets/ui/Header/ui/DropdownHeader/DropdownHeader';
import { ThemeToggle } from '@/shared/ui/ThemeToggle/ThemeToggle';
var HeaderProfile = function (_a) {
    var token = _a.token, userData = _a.userData;
    var history = useHistory();
    var handleLogIn = function () {
        history.push(RoutePath.login);
    };
    return (_jsx(S.HeaderProfileContainer, { children: token ?
            _jsxs(_Fragment, { children: [_jsx(ThemeToggle, {}), _jsx("div", __assign({ style: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px' } }, { children: _jsx(Icon, { css: { display: 'flex', justifyContent: 'flex-end', height: '20px', fill: '$primaryText' }, IconItem: Search }) })), _jsx("div", __assign({ style: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px' } }, { children: _jsx(Icon, { IconItem: Notification, css: { fill: '$primaryText' } }) })), _jsx("div", __assign({ style: { marginLeft: '8px' } }, { children: _jsx(DropdownHeader, { trigger: _jsx(AvatarWithName, { userData: userData }) }) }))] })
            :
                _jsxs(_Fragment, { children: [_jsx(ThemeToggle, {}), _jsx("div", __assign({ style: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px' } }, { children: _jsx(Icon, { css: { display: 'flex', justifyContent: 'flex-end', height: '20px', fill: '$primaryText' }, IconItem: Search }) })), _jsx(Button, __assign({ onClick: handleLogIn, css: { background: 'transparent', color: '$primaryText', fontSize: '20px' } }, { children: "Log in" }))] }) }));
};
export { HeaderProfile };
