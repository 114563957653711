import { styled } from '@stitches/react';
export var InformerWrapper = styled('div', {
    display: 'flex',
    gap: '12px',
    padding: '20px',
    borderRadius: '20px',
    variants: {
        type: {
            attention: {
                border: '1px solid $yellow',
                background: '$lightYellow',
                alignItems: 'center',
            },
            info: {
                border: '1px solid $aquamarine',
                background: '$lightAquamarine',
            },
            warning: {
                border: '1px solid $brightPink',
                background: '$palePink',
            },
        },
    },
});
