var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Switch } from 'react-router-dom';
import { Suspense } from 'react';
import { routeConfig } from '@/shared/config/routeConfig/routeConfig';
import { WithAuth } from '@/shared/hoc/withAuth/withAuth';
import { MainLayout } from '@/app/providers/layouts/ui/MainLayout/MainLayout';
import { Footer } from '@/widgets/ui/Footer/Footer';
import { ignoreFooterList, ignoreHeaderList } from '@/app/providers/router/ui/data';
import { Header } from '@/widgets/ui/Header/ui/Header';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import ScrollToTop from '@/app/providers/router/ui/ScrollToTop';
var AppRouter = function () {
    var isAuth = useTypedSelector(function (state) { return state.userReducer.isAuth; });
    return (_jsxs(_Fragment, { children: [_jsx(ScrollToTop, {}), _jsx(Route, { render: function (_a) {
                    var location = _a.location;
                    var paths = location.pathname.split('/');
                    return !ignoreHeaderList["/".concat(paths[1])] && _jsx(Header, {});
                } }), _jsxs(Suspense, __assign({ fallback: _jsx("p", { children: "Loading..." }) }, { children: [_jsx(Switch, { children: Object.values(routeConfig).map(function (_a) {
                            var id = _a.id, element = _a.element, path = _a.path, isProtect = _a.isProtect, variant = _a.variant, isApplyLayout = _a.isApplyLayout;
                            return (isProtect ?
                                _jsx(Route, { exact: true, path: path, render: function () { return (_jsx(MainLayout, __assign({ isApplyLayout: isApplyLayout, background: variant.background }, { children: _jsx(WithAuth, __assign({ isAuth: isAuth }, { children: element })) }))); } }, id)
                                :
                                    _jsx(Route, { exact: true, path: path, render: function () { return (_jsx(_Fragment, { children: _jsx(MainLayout, __assign({ isApplyLayout: isApplyLayout, background: path === '/news' && isAuth ? 'gray' : variant.background }, { children: element })) })); } }, id));
                        }) }), _jsx(Route, { render: function (_a) {
                            var location = _a.location;
                            var paths = location.pathname.split('/');
                            var path = ignoreFooterList["/".concat(paths[1])];
                            return (!path || (path && path.isAuthZone && !isAuth)) && _jsx(Footer, {});
                        } })] }))] }));
};
export { AppRouter };
