var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { ReactComponent as Telegram } from '@/shared/assets/icons/social/Telegram.svg';
import { ReactComponent as Youtube } from '@/shared/assets/icons/social/Youtube.svg';
import { categoriesData, data } from './data';
import * as S from './style';
var Footer = function () {
    var history = useHistory();
    var navigate = function (path) {
        history.push(path);
    };
    return (_jsx(S.FooterWrapper, { children: _jsxs(S.Footer, { children: [_jsxs(S.FooterLeftColumn, { children: [_jsxs(S.FooterLogo, { children: [_jsx(S.LogoIcon, {}), _jsx(S.ShortLogoIcon, {})] }), _jsxs("div", { children: [_jsx(S.FooterLeftText, { children: "Quantity Ltd" }), _jsx(S.FooterLeftText, { children: "17-21 Bellevue Street, Surry Hills. USA" })] }), _jsxs(S.FooterSocialMedia, { children: [_jsx(Telegram, { style: { color: '#FFFFFF' } }), _jsx(Youtube, { style: { color: '#FFFFFF' } })] })] }), _jsxs(S.FooterRightColumn, { children: [_jsx(S.FooterCategoriesWrapper, { children: categoriesData.map(function (item) { return (_jsx(S.FooterText, { children: item.title }, item.id)); }) }), _jsx(S.FooterLinksWrapper, { children: data.map(function (item, index) { return (_jsx(S.FooterLinks, { children: item.map(function (child) { return (_jsx(S.FooterLink, __assign({ onClick: function () { return navigate(child.path); } }, { children: child.title }), child.id)); }) }, index)); }) })] })] }) }));
};
export { Footer };
