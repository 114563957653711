import { styled } from '@/app/styles/mainStyles';
export var BlueHintContainer = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    background: '$bgAccent',
    border: '1px solid $hasSubscriptionStroke',
    borderRadius: '16px',
    padding: '16px 20px',
    alignItems: 'center',
    gap: '12px',
    color: '$primaryText',
});
export var InfoIconWrapper = styled('div', {
    padding: '2px 0',
    color: '$primaryText',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
});
