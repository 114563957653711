import { styled } from '@/app/styles/mainStyles';
export var HeaderProfileContainer = styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    gap: '8px',
});
export var AvatarWithNameContainer = styled('div', {});
