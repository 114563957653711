var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import axios from 'axios';
import Cookies from 'universal-cookie';
import handlerErrors from '@/shared/helpers/handlerErrors/handlerErrors';
import { BASE_URL } from '@/shared/api/api';
var cookies = new Cookies();
var initialState = {
    isAuth: !!cookies.get('refresh'),
    userStatus: BASE_FETCH_STATUS,
    fetchUserData: BASE_FETCH_STATUS,
    userData: null,
    notificationsSettings: null,
};
var login = createAsyncThunk('userModalSlice/login', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1, error;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("".concat(BASE_URL, "login/"), payload, {
                        withCredentials: false,
                    })];
            case 1:
                resp = _b.sent();
                cookies.set('access', resp.data.access, { maxAge: 15 * 60, path: '/' });
                cookies.set('refresh', resp.data.refresh, { maxAge: 24 * 60 * 60, path: '/' });
                return [2 /*return*/, resp];
            case 2:
                e_1 = _b.sent();
                error = e_1;
                if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) {
                    handlerErrors(error.response.data);
                }
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var loginGoogle = createAsyncThunk('userModalSlice/loginGoogle', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_2, error;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("".concat(BASE_URL, "google-login/"), null, {
                        withCredentials: false,
                    })];
            case 1: return [2 /*return*/, _b.sent()];
            case 2:
                e_2 = _b.sent();
                error = e_2;
                if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) {
                    handlerErrors(error.response.data);
                }
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var register = createAsyncThunk('userModalSlice/register', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('register/', payload, {
                        withCredentials: false,
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/, true];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getProfile = createAsyncThunk('userModalSlice/getProfile', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get('profile/')];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var removeUser = createAsyncThunk('userModalSlice/removeUser', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete('profile-delete/')];
            case 1:
                _a.sent();
                cookies.remove('access');
                cookies.remove('refresh');
                return [3 /*break*/, 3];
            case 2:
                e_5 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_5)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateProfile = createAsyncThunk('userModalSlice/updateProfile', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch('profile-update/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_6 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_6)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updatePassword = createAsyncThunk('userModalSlice/updatePassword', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch('profile-update-password/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_7 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_7)];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var passwordReset = createAsyncThunk('userModalSlice/passwordReset', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('password-reset/', payload)];
            case 1:
                _a.sent();
                return [2 /*return*/, true];
            case 2:
                e_8 = _a.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); });
var logout = createAsyncThunk('userModalSlice/logout', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var refresh, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                refresh = cookies.get('refresh');
                return [4 /*yield*/, thunkAPI.extra.api.post('logout/', { refresh: refresh })];
            case 1:
                _a.sent();
                cookies.remove('access');
                cookies.remove('refresh');
                return [2 /*return*/, true];
            case 2:
                e_9 = _a.sent();
                return [2 /*return*/, false];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getNotificationSettings = createAsyncThunk('userModalSlice/getNotificationSettings', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get('notification-settings/')];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_10 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_10)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateNotificationSettings = createAsyncThunk('userModalSlice/updateNotificationSettings', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('notification-settings/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_11 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_11)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var userModelSlice = createSlice({
    name: 'userModelSlice',
    initialState: initialState,
    reducers: {
        setAuth: function (state, _a) {
            var payload = _a.payload;
            state.isAuth = payload;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(login.pending, function (state) {
            state.userStatus = START_FETCH_STATUS;
        });
        builder.addCase(login.rejected, function (state, _a) {
            var payload = _a.payload;
            state.userStatus.isLoading = false;
            state.userStatus.error = payload;
        });
        builder.addCase(login.fulfilled, function (state) {
            state.userStatus.isLoading = false;
            state.isAuth = true;
        });
        builder.addCase(getProfile.pending, function (state) {
            state.fetchUserData = START_FETCH_STATUS;
        });
        builder.addCase(getProfile.rejected, function (state, _a) {
            var payload = _a.payload;
            state.fetchUserData.isLoading = false;
            state.fetchUserData.error = payload;
        });
        builder.addCase(getProfile.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.fetchUserData.isLoading = false;
            state.isAuth = true;
            state.userData = payload;
        });
        builder.addCase(updateProfile.pending, function (state) {
            state.fetchUserData = START_FETCH_STATUS;
        });
        builder.addCase(updateProfile.rejected, function (state, _a) {
            var payload = _a.payload;
            state.fetchUserData.isLoading = false;
            state.fetchUserData.error = payload;
        });
        builder.addCase(updateProfile.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.fetchUserData.isLoading = false;
            state.userData = payload;
        });
        builder.addCase(updatePassword.pending, function (state) {
            state.fetchUserData = START_FETCH_STATUS;
        });
        builder.addCase(updatePassword.rejected, function (state, _a) {
            var payload = _a.payload;
            state.fetchUserData.isLoading = false;
            state.fetchUserData.error = payload;
        });
        builder.addCase(updatePassword.fulfilled, function (state) {
            state.fetchUserData.isLoading = false;
        });
        builder.addCase(removeUser.pending, function (state) {
            state.fetchUserData = START_FETCH_STATUS;
        });
        builder.addCase(removeUser.rejected, function (state, _a) {
            var payload = _a.payload;
            state.fetchUserData.isLoading = false;
            state.fetchUserData.error = payload;
        });
        builder.addCase(removeUser.fulfilled, function (state) {
            state.fetchUserData.isLoading = false;
            state.isAuth = false;
            state.userData = null;
        });
        builder.addCase(logout.fulfilled, function (state) {
            state.isAuth = false;
            state.userData = null;
        });
        builder.addCase(getNotificationSettings.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.notificationsSettings = payload;
        });
        builder.addCase(updateNotificationSettings.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.notificationsSettings = payload;
        });
    },
});
export default userModelSlice.reducer;
export var setAuth = userModelSlice.actions.setAuth;
export { login, getProfile, updateProfile, updatePassword, register, removeUser, logout, getNotificationSettings, updateNotificationSettings, loginGoogle, };
