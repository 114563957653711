import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
export var categoriesData = [
    {
        id: 1,
        title: 'Products',
    },
    {
        id: 2,
        title: 'Company',
    },
    {
        id: 3,
        title: 'Documents',
    },
];
export var data = [
    [
        {
            id: 'Databases',
            title: 'Databases',
            path: RoutePath.databases,
        },
        {
            id: 'Strategies',
            title: 'Strategies',
            path: RoutePath.strategies,
        },
        {
            id: 'Analytics',
            title: 'Analytics',
            path: '/',
        },
        {
            id: 'News',
            title: 'News',
            path: RoutePath.news,
        },
    ],
    [
        {
            id: 'Pricing',
            title: 'Pricing',
            path: '/',
        },
        {
            id: 'Partnership',
            title: 'Partnership',
            path: '/',
        },
        {
            id: 'API',
            title: 'API',
            path: '/',
        },
        {
            id: 'HelpCenter',
            title: 'Help Center',
            path: '/',
        },
    ],
    [
        {
            id: 'PrivacyPolicy',
            title: 'Privacy Policy',
            path: '/',
        },
        {
            id: 'Cookie policy',
            title: 'Cookie policy',
            path: '/',
        },
    ],
];
