var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import * as S from './style';
var RadioButton = function (_a) {
    var isCheckedProp = _a.isChecked, onChange = _a.onChange, type = _a.type, _b = _a.disabled, disabled = _b === void 0 ? false : _b, css = _a.css;
    var _c = useState(isCheckedProp), isChecked = _c[0], setIsChecked = _c[1];
    // Обновление isChecked при изменении isCheckedProp
    useEffect(function () {
        setIsChecked(isCheckedProp);
    }, [isCheckedProp]);
    var onClick = function () {
        if (onChange) {
            onChange();
        }
        setIsChecked(!isChecked);
    };
    return (_jsxs(_Fragment, { children: [type === 'default' &&
                _jsx(S.ToggleContainer, __assign({ css: css, disabled: disabled, className: isChecked ? 'checked' : '', onClick: onClick }, { children: _jsx(S.ToggleSwitch, { className: isChecked ? 'checked' : '' }) })), type === 'enabledOnly' &&
                _jsx(S.ToggleContainer, __assign({ css: css, disabled: disabled, className: 'enabled' }, { children: _jsx(S.ToggleSwitch, { className: 'enabled' }) }))] }));
};
export { RadioButton };
