import Search from '@/shared/assets/icons/SearchIcon.svg';
import { styled } from '@/app/styles/mainStyles';
export var InputWrapper = styled('div', {
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '16px',
    border: '1px solid transparent',
    variants: {
        type: {
            default: {
                '&:hover': {
                    outline: 'none',
                    border: '1px solid $controlsStrokeHover',
                },
                '&:focus': {
                    outline: 'none',
                    border: '1px solid $inputBorderFocus',
                    '& + span': {
                        top: '12px',
                        fontSize: '12px',
                    },
                },
            },
            auth: {},
            filterSearch: {},
            filerInput: {},
        },
        error: {
            true: {
                outline: 'none',
                border: '1px solid $inputBorderError',
                transition: '0.5s',
                '&:hover': {
                    border: '1px solid $inputBorderError',
                },
                '&:focus': {
                    border: '1px solid $inputBorderError',
                },
            },
        },
    },
    '&[data-state-disabled="true"]': {
        '&:hover': {
            border: '1px solid transparent',
        },
    },
});
export var Label = styled('p', {
    position: 'absolute',
    pointerEvents: 'none',
    left: '16px',
    top: '18px',
    fontSize: '20px',
    lineHeight: '28px',
    color: '$textSecondary',
    transition: '0.2s ease all',
    '&[data-value-attribute="true"]': {
        top: '8px',
        fontSize: '12px',
        lineHeight: '16px',
    },
    '&[data-state-disabled="true"]': {
        color: '$textDisabled',
    },
});
export var Input = styled('input', {
    background: '$bgInput',
    border: '1px solid transparent',
    outline: 'none',
    fontFamily: '$montserrat',
    borderRadius: '16px',
    fontWeight: 400,
    paddingLeft: '6px',
    padding: '13px 16px',
    boxSizing: 'border-box',
    color: '$primaryText',
    fontSize: '20px',
    lineHeight: '28px',
    '&[type="password"]': {
        paddingRight: '56px',
    },
    variants: {
        size: {
            big: {
                fontSize: '20px',
                fontWeight: '400',
                borderRadius: '16px',
                lineHeight: '28px',
                padding: '20px 10px 20px 10px',
            },
            small: {
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '22px',
                height: '36px',
                borderRadius: '8px',
                padding: '13px 10px 13px 10px',
            },
            default: {},
        },
        typeInput: {
            default: {
                height: '60px',
                width: '100%',
                '&::placeholder': {
                    color: '$darkText',
                },
            },
            auth: {},
            filterSearch: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                fontWeight: '400',
                padding: '13px 10px 13px 46px',
                height: '36px',
                borderRadius: '8px',
                border: '1px solid $controlsStroke',
            },
            filerInput: {
                border: '1px solid $controlsStroke',
                height: '36px',
                borderRadius: '8px',
                color: '$black',
            },
        },
        withLabel: {
            true: {
                padding: '22px 16px 13px 16px',
                '&[data-value-attribute="true"]': {
                    padding: '22px 16px 8px 16px',
                },
            },
        },
        iconInside: {
            true: {
                backgroundImage: "url(".concat(Search, ")"),
                backgroundRepeat: 'no-repeat',
                backgroundSize: '18px 18px',
                backgroundPosition: 'left 6px center',
                padding: '10px 0 8px 30px',
            },
        },
    },
    '&:disabled': {
        '&::placeholder': {
            color: '$textDisabled',
        },
    },
});
export var ErrorContainer = styled('div', {
    padding: '0 16px',
    marginTop: '4px',
});
export var IconWrapper = styled('button', {
    display: 'inline-flex',
    position: 'absolute',
    top: '50%',
    right: '16px',
    padding: '8px',
    background: 'none',
    border: 'none',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    transition: 'background .2s',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        background: '#D0D5DD',
    },
});
